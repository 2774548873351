<!--
 * @Author: your name
 * @Date: 2021-08-01 13:21:04
 * @LastEditTime: 2021-08-18 17:52:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\user\userCenter\index.vue
-->
<template>
  <div class="sign">
    <div class="tabs">
      <span
        :class="{ active: tab === '' }"
        @click="
          tab = '';
          currPage = 1;
          getList();
        "
        >全部</span
      >
      <span
        :class="{ active: tab === 0 }"
        @click="
          tab = 0;
          currPage = 1;
          getList();
        "
        >审核中</span
      >
      <span
        :class="{ active: tab == 1 }"
        @click="
          tab = 1;
          currPage = 1;
          getList();
        "
        >已通过</span
      >
      <span
        :class="{ active: tab == 4 }"
        @click="
          tab = 4;
          currPage = 1;
          getList();
        "
        >未通过</span
      >
      <span
        :class="{ active: tab == 3 }"
        @click="
          tab = 3;
          currPage = 1;
          getList();
        "
        >已结束</span
      >
    </div>
    <div class="main">
      <div class="activityList flex-h" v-if="list.length">
        <smallHd  :objData="item" v-for="(item, index) in list" :key="index" />
      </div>
      <div class="Pagination" v-if="list.length">
        <el-pagination
          size="small"
          background
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="limit"
          layout=" prev, pager,  next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div class="null flex-v flex-hc flex-vc" v-else>
        <img src="@/assets/img/null.png" />
        <span>暂无报名的活动</span>
        <p @click="$router.push({ name: 'activity' })">找活动</p>
      </div>
    </div>
  </div>
</template>
<script>
import { applyList } from "@/api/index";
import smallHd from "@/components/small_hd.vue";
export default {
  components: {
    smallHd,
  },
  data() {
    return {
      tab: '',
      limit: 6,
      total: 0,
      currPage: 1,
      list: [],
    };
  },

  created() {
    this.getList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    getList() {
      applyList({
        currPage: this.currPage,
        pageLimit: this.limit,
        status: this.tab,
      }).then((res) => {
        this.list=res.data.list
        this.total=res.data.totalCount
      });
    },
  },
};
</script>
<style lang="scss" scoped>
    .sign {
        .tabs {
            margin-left: 22px;
            font-size: 16px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            // color: #FF6E6E;
            color: #545353;
            margin-top: 20px;

            span {
                margin-right: 55px;
                cursor: pointer;

                &.active {
                    color: #ff6e6e;
                }
            }
        }

        .main {
            width: 819px;
            height: 605px;
            background: #fff6f6;
            border-radius: 23px;
            margin: 0 auto;
            margin-top: 10px;
            box-sizing: border-box;
            padding: 26px 26px 0 26px;

            .activityList {
                flex-wrap: wrap;

                dl {
                    width: 245px;
                    height: 252px;
                    background: #ffffff;
                    box-shadow: 0px 6px 4px 0px #d8d8d8;
                    border-radius: 10px;
                    margin-bottom: 14px;
                    cursor: pointer;

                    dt {
                        position: relative;
                        height: 137px;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        .tag {
                            width: 60px;
                            height: 18px;
                            background: #fb3947;
                            border-radius: 14px;
                            text-align: center;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #ffffff;
                            line-height: 18px;
                            position: absolute;
                            top: 7px;
                            left: 7px;
                        }

                        .type {
                            width: 79px;
                            height: 28px;
                            border-radius: 0px 0px 0px 10px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            text-align: center;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #ffffff;
                            line-height: 28px;

                            &.success {
                                background: #2ea25b;
                            }

                            &.loading {
                                background: #a8a6a6;
                            }

                            &.error {
                                background: #fb3947;
                            }
                        }
                    }

                    dd {
                        h1 {
                            width: 200px;
                            height: 28px;
                            font-size: 14px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #1a1a1a;
                            line-height: 28px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-left: 10px;
                            line-height: 28px;
                        }

                        p {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #1a1a1a;
                            margin-left: 10px;
                            margin-bottom: 2px;
                            //   margin-left: 15px;
                        }
                    }
                }
            }

            .Pagination {               
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-35%, 0%);
            }

            .null {
                padding-top: 150px;

                img {
                    width: 70px;
                }

                span {
                    font-size: 14px;
                    font-family: MicrosoftYaHei;
                    color: #ff6e6e;
                    margin: 15px 0;
                }

                p {
                    width: 169px;
                    height: 42px;
                    background: #ff6e6e;
                    border-radius: 26px;
                    cursor: pointer;
                    text-align: center;
                    font-size: 18px;
                    font-family: MicrosoftYaHei;
                    color: #ffffff;
                    line-height: 42px;
                }
            }
        }
    }
</style>