<!--
 * @Author: your name
 * @Date: 2021-08-06 14:49:02
 * @LastEditTime: 2021-08-20 14:49:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\components\small_hd.vue
-->
<template>
  <dl @click="$router.push({ name: 'activityDetail',query:{id:objData.activityId} })">
    <dt>
      <img :src="objData.activityLogo" />

      <div class="tag" v-if="objData.status == 0">即将开始</div>
      <div class="tag" v-if="objData.status == 1">正在进行</div>
      <div class="tag" v-if="objData.status == 2">已结束</div>
      <div class="type loading" v-if="objData.auditStatus == 0">审核中</div>
      <div class="type success" v-if="objData.auditStatus == 1">已通过</div>
      <div class="type error" v-if="objData.auditStatus == 2">未通过</div>
    </dt>
    <dd>
      <h1>{{ objData.activityName }}</h1>
      <p>
        活动时间：{{ $formatDate(objData.startTime, "{y}/{m}/{d}") }} -
        {{ $formatDate(objData.endTime, "{y}/{m}/{d}") }}
      </p>
      <p>活动地点：{{ objData.activityPlace }}</p>
      <p>
        已招募：{{ objData.numberParticipants }}/{{ objData.activityMember }}
      </p>
    </dd>
  </dl>
</template>
<script>
export default {
  props: {
    objData: {
      type: Object,
      default: {
        serviceOrientation: [],
      },
    },
  },
};
</script>
<style lang="scss" scoped>
dl {
  width: 245px;
  height: 252px;
  background: #ffffff;
  box-shadow: 0px 6px 4px 0px #d8d8d8;
  border-radius: 10px;
  margin: 0 5px;
  margin-bottom: 14px;
  cursor: pointer;
  dt {
    position: relative;
    height: 137px;
    img {
      width: 100%;
      height: 100%;
    }
    .tag {
      width: 60px;
      height: 18px;
      background: #fb3947;
      border-radius: 14px;
      text-align: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      position: absolute;
      top: 7px;
      left: 7px;
    }
    .type {
      width: 79px;
      height: 28px;
      border-radius: 0px 0px 0px 10px;
      position: absolute;
      right: 0;
      top: 0;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 28px;
      &.success {
        background: #2ea25b;
      }
      &.loading {
        background: #a8a6a6;
      }
      &.error {
        background: #fb3947;
      }
    }
  }
  dd {
    h1 {
      width: 200px;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1a1a1a;
      line-height: 28px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 10px;
      line-height: 28px;
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1a1a1a;
      margin-left: 10px;
      margin-bottom: 2px;
      //   margin-left: 15px;
    }
  }
}
</style>